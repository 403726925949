.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 30px;
}

.notFoundText {
  font-size: 5rem;
  color: #600;
}
