.wizard-footer {
  justify-content: space-between;
}

.example-text {
  text-align: center;
  font-size: 1.1rem;
}

.text-larger {
  font-size: 1.1rem;
}

.intro-text {
  font-size: 1rem;
}

.reward-followers-input {
  max-width: 125px;
}

.wizard-welcome-intro {
  font-size: 1rem;
}

.wizard-welcome-intro li {
  font-size: 0.9rem;
  color: #555;
}

.wizard-modal-header {
  display: block;
}

.wizard-modal-header .close {
  position: absolute;
  right: 15px;
  top: 10px;
}

.invalid-feedback {
  font-size: 100%;
}

form:not(.was-validated) input:required {
  box-shadow: none;
}

form:not(.was-validated) textarea:required {
  box-shadow: none;
}

.brand-logo-display {
  max-width: 128px;
}
