.loader {
  position: absolute;
  top: 20%;
  left: 50%;
  margin-left: -25px;
  margin-top: -20px;

  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.loader > div {
  background-color: #f96332;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-left: 3px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loaderR2 {
  -webkit-animation-delay: -1.1s !important;
  animation-delay: -1.1s !important;
}

.loaderR3 {
  -webkit-animation-delay: -1s !important;
  animation-delay: -1s !important;
}

.loaderR4 {
  -webkit-animation-delay: -0.9s !important;
  animation-delay: -0.9s !important;
}

.loaderR5 {
  -webkit-animation-delay: -0.8s !important;
  animation-delay: -0.8s !important;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
