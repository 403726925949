.invalidInput {
  font-size: 0.9rem;
  color: #dc3545;
  text-align: center;
  margin-top: 0.4rem;
}

.verifyLink {
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
}

.copyBtn {
  display: block;
  margin: 1em auto;
}
