.sidebar {
  bottom: 0;
  justify-content: flex-end;
  left: calc(50vw - 1350px / 2);
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 240px;
  z-index: 10;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.main {
  overflow-x: hidden;
  padding-left: calc((50vw - 1390px / 2 + 278px));
  z-index: 0;
  height: 100vh;
}

.topContent {
  height: 40px;
  left: calc((50vw - 1390px / 2 + 278px) - 4px);
  margin: 0 -4px;
  width: calc(100vw - 278px - 24px + 16px);
  max-width: 1108px;
  min-width: 776px;
  padding-left: 8px;
  padding-right: 8px;
  position: fixed;
  top: 0;
  z-index: 11;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #454255 !important;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.5);
}

.content {
  max-width: 1092px;
  min-width: 760px;
  width: calc(100vw - 278px - 24px);
  margin-bottom: 3rem;
  padding-top: 50px;
  position: relative;
}

.contentInner {
  padding: 2rem 2rem;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.15);
}

.outerContainer {
  position: relative;
}

@media only screen and (max-width: 1410px) {
  .sidebar {
    left: 20px;
  }
  .topContent {
    left: 274px;
    width: calc(100vw - 278px - 24px + 16px);
  }
  .main {
    padding-left: 278px;
  }
  .content {
    width: calc(100vw - 278px - 24px);
  }
}
@media only screen and (max-width: 1060px) {
  .sidebar {
    width: 206px;
    position: absolute;
    height: 100vh;
  }
  .topContent {
    width: calc(100vw - 244px - 24px + 16px);
    left: 240px;
    position: absolute;
  }
  .main {
    padding-left: 244px;
  }
  .content {
    width: calc(100vw - 244px - 24px);
  }
}
