.spaceRight {
  margin-right: 2rem;
}

.modalBody {
  max-height: 70vh;
  overflow-y: auto;
}

.modalBody h2 {
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.modalBody h3 {
  font-size: 1.1rem;
}

.dateFooter {
  margin-top: 3rem;
  font-size: 0.8rem;
}
