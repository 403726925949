.container {
  color: #fff;
  display: flex;
  align-items: center;
}

.adminLabel {
  min-width: 125px;
  margin: 0;
}

.previewingText {
  margin: 0;
  font-weight: bold;
  color: rgb(218, 136, 136);
}

.previewContent > * {
  text-align: center;
}

.stopPreviewLink {
  display: block;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: -5px;
  color: white;
}

.stopPreviewLink:hover {
  color: #ccc;
}

.newBrandBtn {
  margin-left: 10px;
}
