.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #454255;
  height: 100vh;
}

.content {
  min-width: 450px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.15);
}

.form {
  border-radius: 10px;
  padding: 0 1rem 0 1rem;
}

.heading {
  color: #f96332;
  text-shadow: 2px 2px 2px #bbb;
  text-align: center;
}

.componentHeading {
  display: flex;
  justify-content: center;
  margin: 0 auto 0 auto;
  text-align: center;
  margin-bottom: 1rem;
}

.headerMainText {
  position: relative;
}

.spacer {
  margin-top: 2.3rem;
}

.subheading {
  font-size: 0.85rem;
  position: absolute;
  top: 2px;
  left: 100%;
  color: #999;
  margin-left: 7px;
  text-transform: capitalize;
  text-align: left;
  min-width: 100px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.actions > a {
  max-width: 200px;
}

.loadingContainer {
  position: relative;
}

.componentWhileLoading {
  opacity: 0.3;
}

.tcCheckContainer {
  margin: 1.5rem auto 1.5rem auto;
  max-width: 20rem;
}

.planHeader {
  text-align: center;
  font-size: 1.2rem;
}

.planDesc {
  font-size: 0.9rem;
  text-align: center;
}

.selectContainer {
  margin-bottom: 2rem;
}

@media (max-width: 576px) {
  .container {
    display: block;
    margin-top: 15px;
  }

  .content {
    min-width: 0;
    border-radius: 0;
  }
}
