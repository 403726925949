.setupText {
  margin-left: 7px;
}

.welcomeText {
  font-size: 1.25rem;
  max-width: 600px;
}

.templateText {
  font-size: 1.05rem;
}

.templateList {
  max-width: 350px;
  margin: 1rem auto 0 auto;
}

.templateList > li {
  padding: 0;
}

.templateList > li > a {
  text-align: center;
  display: block;
  padding: 0.5rem 2rem;
}

.list > li,
.list > a {
  display: flex;
}

.feather {
  color: #999;
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
}

.done,
.done:hover {
  background-color: rgb(196, 255, 196);
}

li:not(.done) span {
  border-bottom: 3px solid orange;
}

.list .done .feather,
.list .done:hover .feather {
  color: green;
}

.itemAction {
  cursor: pointer;
}
