/* Nav Links */

.navLink {
  font-weight: 500;
  color: #eee;
  padding: 0.1rem 5px;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.navLink:hover {
  color: #eee;
  text-decoration: none;
}

.feather {
  margin: -2px 4px 0 0;
  color: #999;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.navLink.active {
  color: #007bff;
}

.navLink:hover .feather,
.navLink.active .feather {
  color: inherit;
}

/* Nav-Bar */

.nav .active {
  background-color: #fff;
}

.sidebarPrimaryHeading {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #f9b6a1;
  font-weight: bold;
  margin: 0.8rem 0;
}

.sidebarHeading {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #b6b0d8;
  font-weight: bold;
  margin: 0.8rem 0;
}

.brand {
  font-size: 1.2rem;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  font-weight: 900;
}

.brandSmall {
  font-size: 0.7rem;
  color: #f9b6a1 !important;
  text-transform: uppercase;
  margin-left: 5px;
  margin-top: -5px;
}

.globalAdminContainer {
  background-color: rgba(228, 92, 92, 0.3);
  border-radius: 4px;
  padding: 3px 10px;
  margin-top: 2em;
}

.globalAdminContainer > h6 {
  color: #fff;
}
