.toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.dropdown {
  margin-right: 5px;
}

.item {
  cursor: pointer;
}

.menu {
  min-width: 15rem;
  /* left: auto !important;
  right: -50px !important; */
}
