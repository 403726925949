.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.selectedContainer > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.selectedContainer > div > h6 {
  text-align: center;
  font-weight: bold;
}

.brandHeader {
  margin-top: 20px;
}

.selectorHeader {
  color: #999;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
}

.active {
  background-color: rgba(45, 92, 45);
  border-radius: 5px;
  color: #fff;
}

.brandList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.brandList li {
  padding: 15px 0;
  position: relative;
}

.brandList li + li {
  border-top: 1px solid #ccc;
}

.delBtn {
  background-color: transparent;
  border: none;
  height: 25px;
  color: red;
  position: absolute;
  right: 0;
  top: calc(50% - 12px);
}

.delBtn:hover {
  opacity: 0.5;
}

.saveBtn {
  align-self: flex-end;
}
