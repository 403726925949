.deets {
	color: #555;
	max-width: 400px;
	display: block;
	margin-top: 5px;
}

.formContainer > * {
	margin-top: 30px;
}

.smallInput {
	width: 120px;
}

.longInput {
	width: 350px;
}

.longestInput {
	width: 550px;
}

.imageFieldPreview {
	max-width: 150px;
	max-height: 150px;
}

.imageFieldChangeLink {
	margin-left: 10px;
	font-weight: bold;
	font-size: 0.8em;
}

.rangeValue {
	margin-left: 15px;
	font-weight: bold;
	font-size: 0.9em;
	color: #999;
}

.rangeInputContainer {
	display: flex;
	align-items: center;
}

/* BrandSelector */

.selectedBrandsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;
}

.selectedBrand {
	font-size: 0.8em;
	padding: 5px 10px;
	margin: 5px;
	border-radius: 3px;
	border: 1px solid #ccc;
}

.brandDelBtn {
	color: red;
	font-weight: bold;
	background-color: transparent;
	border: none;
	padding: 0 0 0 10px;
}
