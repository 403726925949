.codeInput {
  width: 225px;
}

.group {
  display: flex;
  flex-direction: row;
}

.addBtn {
  margin-left: 10px;
  padding: 0 12px;
  font-size: 1.5em;
}

.delBtn {
  margin-left: 10px;
}

.introText {
  margin-bottom: 25px;
}
