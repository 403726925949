@import url(https://fonts.googleapis.com/css?family=Karla:regular,bold,italic|Nunito:regular,bold,italic|Montserrat:regular,bold,italic|Signika:bold);
@import url(https://weloveiconfonts.com/api/?family=fontawesome);
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Helvetica Neue", "sans serif" !important;
  font-size: 0.875rem;
  background-color: #454255 !important;
}

a {
  outline: none;
}

a:hover {
  text-decoration: none !important;
}

.max-width-600 {
  max-width: 600px;
}

.nav-main-routes .active {
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
}

.nav-main-routes .active .feather {
  color: #fff;
}

@media (max-width: 576px) {
  #drift-widget-container {
    display: none;
  }
}



.FormBuilder_deets__3Fgew {
	color: #555;
	max-width: 400px;
	display: block;
	margin-top: 5px;
}

.FormBuilder_formContainer__1uelQ > * {
	margin-top: 30px;
}

.FormBuilder_smallInput__3ZgTU {
	width: 120px;
}

.FormBuilder_longInput__5CqrG {
	width: 350px;
}

.FormBuilder_longestInput__VGQAX {
	width: 550px;
}

.FormBuilder_imageFieldPreview__3a_G- {
	max-width: 150px;
	max-height: 150px;
}

.FormBuilder_imageFieldChangeLink__FUvpF {
	margin-left: 10px;
	font-weight: bold;
	font-size: 0.8em;
}

.FormBuilder_rangeValue__31_FJ {
	margin-left: 15px;
	font-weight: bold;
	font-size: 0.9em;
	color: #999;
}

.FormBuilder_rangeInputContainer__3uD9b {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

/* BrandSelector */

.FormBuilder_selectedBrandsContainer__1vt65 {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-top: 10px;
}

.FormBuilder_selectedBrand__36Yls {
	font-size: 0.8em;
	padding: 5px 10px;
	margin: 5px;
	border-radius: 3px;
	border: 1px solid #ccc;
}

.FormBuilder_brandDelBtn__3HhaD {
	color: red;
	font-weight: bold;
	background-color: transparent;
	border: none;
	padding: 0 0 0 10px;
}

[class*="fontawesome-"]:before {
  font-family: "fontawesome", sans-serif;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.select-search-box {
  width: 300px;
  position: relative;
  background: #fff;
  border-radius: 4px;
}

.select-search-box--multiple {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.select-search-box::after {
  font-family: fontawesome;
  content: "\f078";
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  text-align: center;
  line-height: 35px;
  color: #222f3e;
  z-index: 1;
}

.select-search-box--multiple::after,
.select-search-box--focus::after {
  content: "\f002";
}

.select-search-box__icon--disabled::after {
  content: none !important;
}

.select-search-box--input::after {
  display: none !important;
}

.select-search-box__out {
  display: none;
}

.select-search-box__search {
  display: block;
  width: 100%;
  height: 35px;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  padding: 0 20px;
  color: #222f3e;
  -webkit-appearance: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 35px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

input.select-search-box__search {
  line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
  box-shadow: none;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

.select-search-box__search--placeholder {
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__options,
.select-search-box {
  padding-left: 0;
}

.select-search-box__option {
  font-size: 16px;
  font-weight: 400;
  color: #616b74;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
          user-select: none;
}

.select-search-box__option:first-child {
  border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa;
}

.select-search-box__option--selected {
  background: #54a0ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #2184ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: #666;
  font-weight: bold;
  font-size: 12px;
}

.OrderIntegration_invalidInput__3Koix {
  font-size: 0.9rem;
  color: #dc3545;
  text-align: center;
  margin-top: 0.4rem;
}

.OrderIntegration_verifyLink__1CxAa {
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
}

.OrderIntegration_copyBtn__2juA_ {
  display: block;
  margin: 1em auto;
}

.AppIntro_setupText__262y0 {
  margin-left: 7px;
}

.AppIntro_welcomeText__3AIr8 {
  font-size: 1.25rem;
  max-width: 600px;
}

.AppIntro_templateText__3pquu {
  font-size: 1.05rem;
}

.AppIntro_templateList__1m-4v {
  max-width: 350px;
  margin: 1rem auto 0 auto;
}

.AppIntro_templateList__1m-4v > li {
  padding: 0;
}

.AppIntro_templateList__1m-4v > li > a {
  text-align: center;
  display: block;
  padding: 0.5rem 2rem;
}

.AppIntro_list__2rnGA > li,
.AppIntro_list__2rnGA > a {
  display: -webkit-flex;
  display: flex;
}

.AppIntro_feather__20eED {
  color: #999;
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
}

.AppIntro_done__1Lozx,
.AppIntro_done__1Lozx:hover {
  background-color: rgb(196, 255, 196);
}

li:not(.AppIntro_done__1Lozx) span {
  border-bottom: 3px solid orange;
}

.AppIntro_list__2rnGA .AppIntro_done__1Lozx .AppIntro_feather__20eED,
.AppIntro_list__2rnGA .AppIntro_done__1Lozx:hover .AppIntro_feather__20eED {
  color: green;
}

.AppIntro_itemAction__pFIma {
  cursor: pointer;
}

.Logo_container__hCVti {
  font-family: "Signika", "Helvetica Neue", "sans serif" !important;
  color: black;
}

.Logo_container__hCVti > span {
  color: #5175f6;
}

.Login_container__1eOjl {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100vw;
  background-color: #454255;
  height: 100vh;
}

.Login_content__3R1GR {
  min-width: 450px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.15);
}

.Login_form__8Mun_ {
  border-radius: 10px;
  padding: 0 1rem 0 1rem;
}

.Login_heading__288Mj {
  color: #f96332;
  text-shadow: 2px 2px 2px #bbb;
  text-align: center;
}

.Login_componentHeading___jkYh {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto 0 auto;
  text-align: center;
  margin-bottom: 1rem;
}

.Login_headerMainText__vXLiq {
  position: relative;
}

.Login_spacer__2J9en {
  margin-top: 2.3rem;
}

.Login_subheading__8QuQK {
  font-size: 0.85rem;
  position: absolute;
  top: 2px;
  left: 100%;
  color: #999;
  margin-left: 7px;
  text-transform: capitalize;
  text-align: left;
  min-width: 100px;
}

.Login_actions__OPKpG {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.Login_actions__OPKpG > a {
  max-width: 200px;
}

.Login_loadingContainer__2CpuL {
  position: relative;
}

.Login_componentWhileLoading__2ViXg {
  opacity: 0.3;
}

.Login_tcCheckContainer__Z0QQG {
  margin: 1.5rem auto 1.5rem auto;
  max-width: 20rem;
}

.Login_planHeader__1iPcX {
  text-align: center;
  font-size: 1.2rem;
}

.Login_planDesc__2CcGE {
  font-size: 0.9rem;
  text-align: center;
}

.Login_selectContainer__3LbYQ {
  margin-bottom: 2rem;
}

@media (max-width: 576px) {
  .Login_container__1eOjl {
    display: block;
    margin-top: 15px;
  }

  .Login_content__3R1GR {
    min-width: 0;
    border-radius: 0;
  }
}

.Loader_loader__qBmj- {
  position: absolute;
  top: 20%;
  left: 50%;
  margin-left: -25px;
  margin-top: -20px;

  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.Loader_loader__qBmj- > div {
  background-color: #f96332;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-left: 3px;

  -webkit-animation: Loader_sk-stretchdelay__mkfU0 1.2s infinite ease-in-out;
  animation: Loader_sk-stretchdelay__mkfU0 1.2s infinite ease-in-out;
}

.Loader_loaderR2__27rcH {
  -webkit-animation-delay: -1.1s !important;
  animation-delay: -1.1s !important;
}

.Loader_loaderR3__3f60K {
  -webkit-animation-delay: -1s !important;
  animation-delay: -1s !important;
}

.Loader_loaderR4__5tB3z {
  -webkit-animation-delay: -0.9s !important;
  animation-delay: -0.9s !important;
}

.Loader_loaderR5__2uA_- {
  -webkit-animation-delay: -0.8s !important;
  animation-delay: -0.8s !important;
}

@-webkit-keyframes Loader_sk-stretchdelay__mkfU0 {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes Loader_sk-stretchdelay__mkfU0 {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.TermsConditions_spaceRight__22Q6c {
  margin-right: 2rem;
}

.TermsConditions_modalBody__2J6UT {
  max-height: 70vh;
  overflow-y: auto;
}

.TermsConditions_modalBody__2J6UT h2 {
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.TermsConditions_modalBody__2J6UT h3 {
  font-size: 1.1rem;
}

.TermsConditions_dateFooter__31RPO {
  margin-top: 3rem;
  font-size: 0.8rem;
}

.VerifyEmail_footerContent__FOJCZ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.VerifyEmail_container__frm37 {
  max-width: 485px;
}

.AdminManagePartners_container__127UL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.AdminManagePartners_selectedContainer__1QLux {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.AdminManagePartners_selectedContainer__1QLux > div {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
}

.AdminManagePartners_selectedContainer__1QLux > div > h6 {
  text-align: center;
  font-weight: bold;
}

.AdminManagePartners_brandHeader__3gIXs {
  margin-top: 20px;
}

.AdminManagePartners_selectorHeader__22lFt {
  color: #999;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
}

.AdminManagePartners_active__3Lr2- {
  background-color: rgba(45, 92, 45);
  border-radius: 5px;
  color: #fff;
}

.AdminManagePartners_brandList__10Mjb {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.AdminManagePartners_brandList__10Mjb li {
  padding: 15px 0;
  position: relative;
}

.AdminManagePartners_brandList__10Mjb li + li {
  border-top: 1px solid #ccc;
}

.AdminManagePartners_delBtn__1NCD6 {
  background-color: transparent;
  border: none;
  height: 25px;
  color: red;
  position: absolute;
  right: 0;
  top: calc(50% - 12px);
}

.AdminManagePartners_delBtn__1NCD6:hover {
  opacity: 0.5;
}

.AdminManagePartners_saveBtn__1Aw7s {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.AdminCodeBlacklist_codeInput__1GO72 {
  width: 225px;
}

.AdminCodeBlacklist_group__3amPJ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.AdminCodeBlacklist_addBtn__kS-yW {
  margin-left: 10px;
  padding: 0 12px;
  font-size: 1.5em;
}

.AdminCodeBlacklist_delBtn__SYUGs {
  margin-left: 10px;
}

.AdminCodeBlacklist_introText__6NJaD {
  margin-bottom: 25px;
}

.AdminTools_container__58_89 {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.AdminTools_adminLabel__1ycYo {
  min-width: 125px;
  margin: 0;
}

.AdminTools_previewingText__3ZdOV {
  margin: 0;
  font-weight: bold;
  color: rgb(218, 136, 136);
}

.AdminTools_previewContent__2Z23s > * {
  text-align: center;
}

.AdminTools_stopPreviewLink__4g4Sf {
  display: block;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: -5px;
  color: white;
}

.AdminTools_stopPreviewLink__4g4Sf:hover {
  color: #ccc;
}

.AdminTools_newBrandBtn__22j7I {
  margin-left: 10px;
}

.Layout_sidebar__36FtZ {
  bottom: 0;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  left: calc(50vw - 1350px / 2);
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 240px;
  z-index: 10;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.Layout_main__3Q7e2 {
  overflow-x: hidden;
  padding-left: calc((50vw - 1390px / 2 + 278px));
  z-index: 0;
  height: 100vh;
}

.Layout_topContent__1A0ZD {
  height: 40px;
  left: calc((50vw - 1390px / 2 + 278px) - 4px);
  margin: 0 -4px;
  width: calc(100vw - 278px - 24px + 16px);
  max-width: 1108px;
  min-width: 776px;
  padding-left: 8px;
  padding-right: 8px;
  position: fixed;
  top: 0;
  z-index: 11;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: #454255 !important;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.5);
}

.Layout_content__1tiDa {
  max-width: 1092px;
  min-width: 760px;
  width: calc(100vw - 278px - 24px);
  margin-bottom: 3rem;
  padding-top: 50px;
  position: relative;
}

.Layout_contentInner__1MWA9 {
  padding: 2rem 2rem;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.15);
}

.Layout_outerContainer__1w7Ms {
  position: relative;
}

@media only screen and (max-width: 1410px) {
  .Layout_sidebar__36FtZ {
    left: 20px;
  }
  .Layout_topContent__1A0ZD {
    left: 274px;
    width: calc(100vw - 278px - 24px + 16px);
  }
  .Layout_main__3Q7e2 {
    padding-left: 278px;
  }
  .Layout_content__1tiDa {
    width: calc(100vw - 278px - 24px);
  }
}
@media only screen and (max-width: 1060px) {
  .Layout_sidebar__36FtZ {
    width: 206px;
    position: absolute;
    height: 100vh;
  }
  .Layout_topContent__1A0ZD {
    width: calc(100vw - 244px - 24px + 16px);
    left: 240px;
    position: absolute;
  }
  .Layout_main__3Q7e2 {
    padding-left: 244px;
  }
  .Layout_content__1tiDa {
    width: calc(100vw - 244px - 24px);
  }
}

.Dropdown_toggle__3n1pE {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.Dropdown_dropdown__wlCrm {
  margin-right: 5px;
}

.Dropdown_item__uiRbU {
  cursor: pointer;
}

.Dropdown_menu__xQCQ5 {
  min-width: 15rem;
  /* left: auto !important;
  right: -50px !important; */
}

/* Nav Links */

.Sidebar_navLink__2uPzG {
  font-weight: 500;
  color: #eee;
  padding: 0.1rem 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 5px;
}

.Sidebar_navLink__2uPzG:hover {
  color: #eee;
  text-decoration: none;
}

.Sidebar_feather__20zpT {
  margin: -2px 4px 0 0;
  color: #999;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.Sidebar_navLink__2uPzG.Sidebar_active__uPfpf {
  color: #007bff;
}

.Sidebar_navLink__2uPzG:hover .Sidebar_feather__20zpT,
.Sidebar_navLink__2uPzG.Sidebar_active__uPfpf .Sidebar_feather__20zpT {
  color: inherit;
}

/* Nav-Bar */

.Sidebar_nav__3PD9E .Sidebar_active__uPfpf {
  background-color: #fff;
}

.Sidebar_sidebarPrimaryHeading__2-hAA {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #f9b6a1;
  font-weight: bold;
  margin: 0.8rem 0;
}

.Sidebar_sidebarHeading__1qiDN {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #b6b0d8;
  font-weight: bold;
  margin: 0.8rem 0;
}

.Sidebar_brand__1wjeS {
  font-size: 1.2rem;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  font-weight: 900;
}

.Sidebar_brandSmall__25IaE {
  font-size: 0.7rem;
  color: #f9b6a1 !important;
  text-transform: uppercase;
  margin-left: 5px;
  margin-top: -5px;
}

.Sidebar_globalAdminContainer__qdK5H {
  background-color: rgba(228, 92, 92, 0.3);
  border-radius: 4px;
  padding: 3px 10px;
  margin-top: 2em;
}

.Sidebar_globalAdminContainer__qdK5H > h6 {
  color: #fff;
}

.wizard-footer {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.example-text {
  text-align: center;
  font-size: 1.1rem;
}

.text-larger {
  font-size: 1.1rem;
}

.intro-text {
  font-size: 1rem;
}

.reward-followers-input {
  max-width: 125px;
}

.wizard-welcome-intro {
  font-size: 1rem;
}

.wizard-welcome-intro li {
  font-size: 0.9rem;
  color: #555;
}

.wizard-modal-header {
  display: block;
}

.wizard-modal-header .close {
  position: absolute;
  right: 15px;
  top: 10px;
}

.invalid-feedback {
  font-size: 100%;
}

form:not(.was-validated) input:required {
  box-shadow: none;
}

form:not(.was-validated) textarea:required {
  box-shadow: none;
}

.brand-logo-display {
  max-width: 128px;
}

.ErrorBoundry_container__1HDyn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.404_container__N_mkx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 30px;
}

.404_notFoundText__1tXoT {
  font-size: 5rem;
  color: #600;
}

