body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Helvetica Neue", "sans serif" !important;
  font-size: 0.875rem;
  background-color: #454255 !important;
}

a {
  outline: none;
}

a:hover {
  text-decoration: none !important;
}

.max-width-600 {
  max-width: 600px;
}

.nav-main-routes .active {
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
}

.nav-main-routes .active .feather {
  color: #fff;
}

@media (max-width: 576px) {
  #drift-widget-container {
    display: none;
  }
}
